<template>
    <Header />
    <section class="mt-70 bg-light-yellow ">
        <header class="heading">
            <h1 class="text-center clr-black ">Website Cloner Online <span class="clr-yellow">Premium</span></h1>
            <div class="article-line article-line-black"></div>
        </header>
        <div class="row">
            <div class="col-md-2 ml-5 mb-5 mr-5 o-2">
                <div class="card-stats">
                    <h2>{{totalWebsite}}</h2>
                    <p><strong>Total Websites Cloned</strong></p>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <form name="crawlerForm">
                    <div class="input-group mb-3 input-url">
                        <div class="input-area">
                            <input type="url" v-model="url" class="form-control input-tool" placeholder="Enter URL" @keyup="error='';success=''" required :disabled="processing">
                            <input type="number" v-model="resultnumber" class="form-control input-tool" :placeholder="number1+'+'+number2+'=?'" @keyup="error='';success=''" required :hidden="processing" style="width:100px;">
                        </div>
                    </div>
                    <div class="input-group mb-3 input-url">
                        <div class="input-area" style="display: block;">
                            <label for="emailme">
                                <input id="emailme" type="checkbox" checked disabled> Email me when cloning is complete (you can close the window after submitting the form, you will receive the files to your email)
                            </label><br>                            
                            <div class="input-group-append input-button">
                                <input type="email" v-model="email" class="form-control input-tool" placeholder="Enter your email" required @keyup="error='';success=''" :hidden="!emailCheckbox" :disabled="processing">
                                <button class="btn arrow-btn" type="button" @click="cloner()" :disabled="processing"><img src="@/assets/images/images-arrow-right.svg"></button>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="progress-bar-1" v-if="status">
                    <div class="progress-bar progress-bar-1-content progress-bar-striped progress-bar-animated">{{status}}</div>
                </div>
                <p v-if="success" class="msg success-msg">{{success}} <br>
                <stripe-checkout
                  ref="checkoutRef"
                  mode="payment"
                  :pk="publishableKey"
                  :line-items="lineItems"
                  :success-url="successURL"
                  :cancel-url="cancelURL"
                  @loading="v => loading = v"
                />
                <button ref="PayButton" @click="submitStripe" style="color: yellow;text-decoration: underline; background-color: transparent; border-color: transparent;">Click here to pay then download your files</button><br>
                And from <a :href="testfolder" target="_blank" style="color: yellow;text-decoration: underline;">here</a> you can see your first cloned page (index) to check our cloning.</p>
                <p v-if="error" class="msg danger-msg">{{error}}</p>
                <!--<a :href="" ref="downloadButton" target="_blank"></a>-->
            </div>
            <div class="col-md-3 mt-4 text-center">
                <!-- <a class="donate-form" href="https://www.paypal.com/donate/?hosted_button_id=XKJXXU74JM7MY" target="_top">
                    <button name="submit" type="submit" class="btn btn-blue ">
                        <i class="fa fa-paypal"></i> Donate via Paypal
                    </button>
                </a> -->
            </div>
        </div>
    </section>
    <section>
        <div class="container mb-200">
            <div class="row">
                <div class="col-lg-8 tool-body-text">
                    <h1>Clone Websites Effortlessly with Webcloner.online</h1>
                    <h2>Discover the easiest way to clone websites with Webcloner.online</h2>
                    <p><strong>Webcloner</strong> is the ultimate online tool for copying websites, offering a seamless experience for just 
                        <b>5.99€ <u>(equivalent to the price of 2 cups of coffee)</u></b>. This Website Copier <span class="supported">supports WordPress </span>
                        and empowers you to download complete site templates, including front-end source code. The process is simple - enter the website URL, and 
                        our Site Downloader tool will crawl and download all assets such as Images, Javascript Files, CSS Files, and Favicon Images. The result? 
                        A ZIP file containing the entire website's source code.<br>
                        Our online web crawler is efficient and user-friendly, enabling you to download entire websites without installing any software on your computer.</p>
                    <p><strong>Note: Ensure you copy the exact URL of the website. Paste the link from the address bar of your targeted website for accurate results. 
                        If you encounter any issues, reach out to us at<em><a href="mailto:contact@aliret.com" target="_blank">contact@aliret.com</a></em>
                        , and we'll handle the process manually for you, sending you the files promptly.</strong></p>
                    <p></p>
                    <p>Key Features of Webcloner:</p>
                    <ol>
                        <li><strong>ProgressBar:</strong> Track the completion percentage of your favorite website with a progress bar.</li>
                        <li><strong>Status Check:</strong> Monitor the downloading process and receive updates on its status.</li>
                        <li><strong>Comprehensive Downloads:</strong> Obtain CSS, JS, fonts, PDFs, images (included in stylesheets), a file with all page URLs, and a file with all asset URLs.</li>
                        <li><strong>Efficiency:</strong> Experience more efficient and precise results compared to other website downloaders.</li>
                        <li><strong>Notification:</strong> Receive an email notification when the copying process is complete.</li>
                    </ol>
                    <h2>Why Choose Webcloner as Your Website Copier:</h2>
                    <p><strong>Low Price and Automated:</strong> Unlike other premium website downloaders, Webcloner is completely low-cost. Automation is our priority to help you save time.</p>
                    <h2>Webcloner Online Features:</h2>
                    <ol>
                        <li><strong>Time-Efficient:</strong> Download or clone any site without a lengthy signup/login process.</li>
                        <li><strong>Speedy Download:</strong> Webcloner quickly downloads website assets, saving you valuable time.</li>
                        <li><strong>Original Asset Names:</strong> Retain the original names of assets, avoiding confusion caused by asset renaming.</li>
                        <li><strong>Ease of Use:</strong>Paste the website URL, click "Copy," and let Webcloner do the rest.</li>
                        <li><strong>No Installation Required:</strong> Skip the hassle of installations, registrations, and solving captchas. Simply paste the website link, hit the Copy button, and you're done.</li>
                    </ol>
                    <h2>Reasons to Use Webcloner.online:</h2>
                    <p>Competitor Analysis: Study your competitor's design offline without repeatedly visiting their website.<br>
                        Site Backup: Easily create a backup of your website for offline use without the need to download each file individually.<br>
                        Design Follow-Up: If you're a web developer or designer, effortlessly follow a specific design by copying the website link and downloading the entire site.</p>
                    <h2><br>Experience the convenience and efficiency of Webcloner.online for all your website cloning needs. Save time, automate the process, and explore the possibilities of website copying without any hassle.</h2>
                    <h2>Call to Action:</h2>
                    <p>Visit <a href="https://webcloner.online/">Webcloner.online</a> today and revolutionize your website cloning workflow. For any inquiries, contact us at contact@aliret.com.</p>
                </div>
                <div class="col-lg-4">
                    <div class="widget categories">
                        <header>
                            <h3 class="h6">Recently Comments</h3>
                        </header>
                        <div v-for="(comment, index) in recentComments" :key="index" class="item">
                            <div class="float-right">
                                <i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                             {{comment.review}}
                        </div>
                    </div>
                    <div class="widget categories">
                        <header>
                            <h1 class="h6">Recently Copied Websites</h1>
                        </header>
                        <div v-for="(website, index) in recentWebsites" :key="index" class="item d-flex justify-content-between" style="overflow: overlay;">
                            <a :href="website.website">{{website.website}}</a>
                        </div>
                    </div>
                    <div class="widget categories">
                        <header>
                            <h3 class="h6">Latest Donations</h3>
                        </header>
                        <div class="item d-flex justify-content-between">
                            <span style="float: left; font-size: unset; color: unset;"> Alan Brandt Consulting</span>
                            <span style="float: right; color: unset;"> €10.00 </span>
                        </div>
                        <div class="item d-flex justify-content-between">
                            <span style="float: left; font-size: unset; color: unset;"> Hannabal</span>
                            <span style="float: right; color: unset;"> $2.00 </span>
                        </div>
                        <div class="item d-flex justify-content-between">
                            <span style="float: left; font-size: unset; color: unset;"> Alamiya decoratif</span>
                            <span style="float: right; color: unset;"> $5.00 </span>
                        </div>
                        <div class="item d-flex justify-content-between">
                            <span style="float: left; font-size: unset; color: unset;"> MISD IT Company</span>
                            <span style="float: right; color: unset;"> $10.00 </span>
                        </div>
                        <div class="item d-flex justify-content-between">
                            <span style="float: left; font-size: unset; color: unset;"> Aliret</span>
                            <span style="float: right; color: unset;"> $5.00 </span>
                        </div>
                    </div>
                    <div class="widget categories" style="">
                        <header>
                            <h3 class="h6">Donate me via Paypal</h3>
                        </header>
                        <center>
                            <form class="donate-form" action="https://www.paypal.com/donate" method="post" target="_blank">
                                <input type="hidden" name="hosted_button_id" value="XKJXXU74JM7MY" />
                                <input type="hidden" name="item_name" value="WebCloner" />
                                <input type="hidden" name="currency_code" value="EUR" />
                                <input type="image" src="https://www.paypalobjects.com/en_US/IT/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                            </form>
                        </center>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</template>

<script>
import { db, firebaseInstance } from "../firebase";
import Header from './../components/Header.vue'
import Footer from './../components/Footer.vue'
import axios from 'axios'
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import Swal from 'sweetalert2'

export default {
  name: 'Welcome',
  components: {
    Header,
    Footer,
    StripeCheckout
  },
  props: {
    msg: String
  },
  mounted(){
    firebaseInstance.auth().signInAnonymously();
    if(this.$route.query.doc) {
        db.collection("websites").doc(this.$route.query.doc).get().then(doc => {
            this.downloadURL = doc.data().zipFolder;
            this.cancelURL = "https://webcloner.online/?pay=canceled&doc="+this.$route.query.doc
            this.url = doc.data().url
            this.testfolder = doc.data().homeDir
            if(doc.data().email)
                this.email = doc.data().email
        });
    }
    if(this.$route.query.pay == 'canceled') {
        Swal.fire('Warn!', 'Your payment was canceled!!','warning');
    }
  },
  data() {
    this.publishableKey = "pk_live_51JdhYtHrXAQjmcCfjqWkx7V2C7FGe8GD3yh9WMIC9JVUCJyLYG17zL1vgW7ZYdJQfaVPkrDKb6PHNSchbiq1nk3j00uhlB8VS2";
    return {
      recentWebsites: [],
      recentComments: [],
      url: "",
      emailCheckbox: true,
      email: "",
      status: "",
      success: "",
      testfolder: "",
      error: "",
      processing: false,
      downloadURL: "",
      totalWebsite: 1012,
      ipAdress: "",
      number1: Math.floor(Math.random() * 100) + 1,
      number2: Math.floor(Math.random() * 10) + 1,
      resultnumber: "",
      docId: "",
      // Stripe
      loading: false,
      lineItems: [
        {
          price: 'price_1OZ6zMHrXAQjmcCf7ejq2y12', // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: 'your-success-url',
      cancelURL: 'https://webcloner.online/?pay=canceled',
    };
  },
  created() {
    db.collection("totalWebsite").doc('kkF5coXxJpiZhyuLNv5o').get().then(doc => {
        this.totalWebsite = doc.data().count;
    });
    db.collection("websites").where("front", "==", false).orderBy('createdAt', 'desc').limit(5).get().then((snapshot) => {
        snapshot.forEach(doc => {
            let website = doc.data().url;
            this.recentWebsites.push({
                website,
            });
        })
    });
    db.collection("comments").orderBy('createdAt', 'desc').limit(5).get().then((snapshot) => {
        snapshot.forEach(doc => {
            let review = doc.data().review;
            let stars = doc.data().stars;
            this.recentComments.push({
                'review': review,
                'stars': stars
            });
        })
    });
    fetch('https://api.ipify.org?format=json').then(x => x.json()).then(({ ip }) => {
        this.ipAdress = ip;
    });
  },
  watch: {
    emailCheckbox: function (val) {
        if(!val)
            this.email = ""
    },
    downloadURL: function (val) {
        if(val) {
            this.success = "Website cloned successfully";
            this.successURL = val;
        }
    },
    docId: function (val) {
        this.cancelURL = "https://webcloner.online/?pay=canceled&doc="+val
    },
  },
  methods: {
    submitStripe () {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
    async cloner() {
        var vm = this;
        var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        var urlRegex = new RegExp(expression);
        if (vm.url && vm.url.match(urlRegex) && vm.url.toLowerCase().indexOf("google.") === -1 && vm.url.toLowerCase().indexOf("facebook.") === -1 && vm.url.toLowerCase().indexOf("amazon.") === -1 && vm.url.toLowerCase().indexOf("youtube.") === -1 && vm.url.toLowerCase().indexOf("yahoo.") === -1 && vm.url.toLowerCase().indexOf("ebay.") === -1 && vm.url.toLowerCase().indexOf("wikipedia.") === -1 && vm.url.toLowerCase().indexOf("reddit.") === -1 && vm.url.toLowerCase().indexOf("linkedin.") === -1 && vm.url.toLowerCase().indexOf("bing.") === -1 && vm.url.toLowerCase().indexOf("netflix.") === -1 && vm.url.toLowerCase().indexOf("instagram.") === -1) {
            if(vm.resultnumber == vm.number1 + vm.number2) {
                var emailExpression = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
                var emailRegex = new RegExp(emailExpression);
                if(vm.emailCheckbox && !vm.email.match(emailRegex)) {
                    vm.success = ""
                    vm.status = ""
                    vm.error = "Please write a valid email address";
                } else {
                    const url = process.env.VUE_APP_HTTP_SERVICE;
                    vm.processing = true
                    vm.status = "Processing.."
                    vm.success = ""
                    vm.error = ""
                    db.collection("websites").add({ 
                        url: vm.url,
                        email: vm.email,
                        ip: vm.ipAdress,
                        createdAt: new Date(),
                        front: true
                    })
                    setTimeout(() => {
                        if(vm.success == "") {
                            vm.status = ""
                            vm.error = "Thank you for waiting ☺ your website is being cloned. You will receive an email when the cloning is complete. Maybe you can find the email in SPAM"
                        }
                    }, 30000);
                    try {
                        var response = await axios.post(url+'clone',{ url: vm.url, email: vm.email, ipAdress: vm.ipAdress });
                        //console.log(response)
                        if(response.status == 201) {
                          vm.docId = response.data[1];
                          vm.downloadURL = response.data[0];
                          vm.testfolder = response.data[2];
                          //setTimeout(() => {
                            //vm.$refs.downloadButton.click()
                            //vm.success = "Website cloned successfully"
                            vm.status = ""
                            vm.error = ""
                            vm.processing = false
                            // make the payment
                            //vm.$refs.PayButton.click()
                          //}, 2000);
                        } else if(response.status == 500) {
                          vm.success = ""
                          vm.status = ""
                          vm.error = response.data
                          vm.processing = false
                        } else {
                          vm.success = ""
                          vm.status = ""
                          vm.processing = false
                          vm.error = response.data
                        }
                    } catch (err) {
                      vm.status = ""
                      vm.error = err.message
                      vm.processing = false
                      vm.success = ""
                    }
                }
            } else {
                vm.success = ""
                vm.status = ""
                vm.error = "Please calculate the equation correctly"
                vm.resultnumber = ""
                vm.number1 = Math.floor(Math.random() * 100) + 1
                vm.number2 = Math.floor(Math.random() * 10) + 1
            } 
        } else {
            vm.success = ""
            vm.status = ""
            vm.error = "Please write a valid URL"
        }
    },
  }
} 
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .supported {
        background-image: linear-gradient(transparent 70%,#db6673 0);
        padding-bottom: 2px;
        background-position: 4px 2px;
        background-repeat: no-repeat;
        display: inline;
        font-weight: 700;
    }
    .success-msg {
        background: green;
        color: white!important;
    }
    .fa-star {
        color: gold; 
    }
</style>
